var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"fill-height primary",staticStyle:{"min-height":"100vh"},attrs:{"fluid":""}},[(_vm.question != null)?[_c('v-row',{staticStyle:{"margin-top":"85px"},attrs:{"align":"center"}},[_c('v-col',{attrs:{"align":"center","cols":"12"}},[(_vm.debug)?[_c('p',{domProps:{"textContent":_vm._s(_vm.question.identifier)}}),_c('p',{domProps:{"textContent":_vm._s(_vm.question.text)}})]:_vm._e(),_c('div',{staticClass:"white--text mb-5",class:[_vm.isMobile ? 'text-h5' : 'text-h4'],domProps:{"innerHTML":_vm._s(_vm.question.question)}}),(_vm.product != null)?_c('v-card',{staticClass:"mx-auto text-left",attrs:{"max-width":"344"}},[_c('v-img',{staticClass:"grey lighten-4 align-end",attrs:{"contain":"","src":'/assets/images/' + _vm.product.image,"height":"240px"}},[(typeof _vm.product.url == 'string')?[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-3 my-3 float-end",attrs:{"fab":"","small":"","color":"white"},on:{"click":function($event){return _vm.openUrl(_vm.product.url)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-information-outline")])],1)]}}],null,false,28489870)},[_c('span',[_vm._v(_vm._s(_vm.appConfig.texts.app.productInfo))])])]:_vm._l((_vm.product.url),function(url,index){return _c('v-tooltip',{key:index,attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-3 my-3",class:url.class,attrs:{"fab":"","small":"","color":"white"},on:{"click":function($event){return _vm.openUrl(url.url)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-information-outline")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.appConfig.texts.app.productInfo)+" "+_vm._s(url.name))])])})],2),_c('v-card-title',{domProps:{"innerHTML":_vm._s(_vm.product.name)}}),(_vm.product.combinedProduct)?_c('v-card-subtitle',[_c('v-row',{staticClass:"px-3 pb-4 caption",attrs:{"justify":"space-between"}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.product.combinedProduct)}}),_c('div',[_vm._v(_vm._s(_vm.product.description)),_c('br')]),_c('div',[_vm._v(" "+_vm._s(_vm.appConfig.texts.app.type)+": "+_vm._s(_vm.product.type)+" "),(_vm.product.lpsClass !='')?_c('div',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" "+_vm._s(_vm.appConfig.texts.app.lpsClass)),_c('sup',[_vm._v("*")])])]}}],null,false,4273474205)},[_c('span',[_vm._v("* "+_vm._s(_vm.appConfig.texts.app.lpsClassDescription)+" ")])]),_vm._v(" : "+_vm._s(_vm.product.lpsClass)+" ")],1):_vm._e()])])],1):_c('v-card-subtitle',[_c('v-row',{staticClass:"px-3 pb-4 caption",attrs:{"justify":"space-between"}},[_c('div',[_vm._v(_vm._s(_vm.product.articleNo)+" - "+_vm._s(_vm.product.description)),_c('br')]),_c('div',[_vm._v(" "+_vm._s(_vm.appConfig.texts.app.type)+": "+_vm._s(_vm.product.type)+" "),(_vm.product.lpsClass !='')?_c('div',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" "+_vm._s(_vm.appConfig.texts.app.lpsClass)),_c('sup',[_vm._v("*")])])]}}],null,false,4273474205)},[_c('span',[_vm._v("* "+_vm._s(_vm.appConfig.texts.app.lpsClassDescription)+" ")])]),_vm._v(" : "+_vm._s(_vm.product.lpsClass)+" ")],1):_vm._e()])])],1)],1):_vm._e(),(_vm.question.alertText != '')?_c('v-alert',{class:[_vm.question.alertClass],attrs:{"max-width":"344","border":"bottom","dark":""},domProps:{"innerHTML":_vm._s(_vm.question.alertText)}}):_vm._e(),_vm._l((_vm.question.buttons),function(btn,n){return [_c('span',{key:n,staticClass:"px-3 pb-4 white mx-sm-5 my-10",staticStyle:{"display":"flex","width":"344px","max-width":"344px"}},[_c('v-text-field',{attrs:{"placeholder":_vm.appConfig.texts.product.select.placeholder,"hide-details":""},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('v-btn',{class:[btn.class, _vm.isMobile ? 'my-1' : ''],staticStyle:{"margin-top":"16px","height":"32px"},attrs:{"depressed":"","small":"","color":"primary","block":_vm.isMobile},on:{"click":function($event){$event.preventDefault();return _vm.setDivider(btn)}}},[(btn.icon && btn.icon != '')?_c('v-icon',{staticClass:"mr-2"},[_vm._v(" "+_vm._s(btn.icon)+" ")]):_vm._e(),_vm._v(" "+_vm._s(btn.name)+" ")],1)],1)]})],2)],1)]:[_c('v-dialog',{attrs:{"persistent":"","max-width":"290"},model:{value:(_vm.error),callback:function ($$v) {_vm.error=$$v},expression:"error"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(" "+_vm._s(_vm.appConfig.texts.errors.qNotFoundTitle)+" ")]),_c('v-card-text',[_vm._v(" "+_vm._s(_vm.appConfig.texts.errors.qNotFoundText)+" ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){$event.preventDefault();return _vm.goBack.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.appConfig.texts.errors.qNotFoundButton)+" ")])],1)],1)],1)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }