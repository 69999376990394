<template>
  <v-container fluid class="fill-height grey" style="min-height: 100vh">
    <v-container>
      <pre>{{questiontest}}</pre>
      <template v-if="questiontest != null">
        <!-- <v-row
          align="center"
          justify="start"
          justify-md="space-around"
          class="px-5"
        >
          <v-col cols="12" lg="8" class="text-center">
            <v-sheet elevation="5" class="py-15 px-5">
              <template v-if="debug">
                <p v-text="questiontest.identifier" />
                <p v-text="questiontest.question" />
              </template>
              <p
                class="mb-5"
                :class="[isMobile ? 'text-h5' : 'text-h4']"
                v-html="questiontest.question"
              />
              <div class="mt-15">
                <template v-for="(btn, n) in questiontest.buttons">
                  <v-btn
                    class="mx-sm-5"
                    depressed
                    color="primary"
                    :block="isMobile"
                    :key="n"
                    :class="[isMobile ? 'my-1' : '']"
                    @click.prevent="selectQuestion(btn)"
                  >
                    <v-icon v-if="btn.icon && btn.icon != ''" class="mr-2">
                      {{ btn.icon }}
                    </v-icon>
                    {{ btn.name }}
                  </v-btn>
                </template>
              </div>
            </v-sheet>
          </v-col>
        </v-row> -->
      </template>
      <template v-else>
        <v-dialog v-model="error" persistent max-width="290">
          <v-card>
            <v-card-title class="text-h5">
              {{ appConfig.texts.errors.qNotFoundTitle }}
            </v-card-title>
            <v-card-text>
              {{ appConfig.texts.errors.qNotFoundText }}
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click.prevent="goBack">
                {{ appConfig.texts.errors.qNotFoundButton }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
    </v-container>
  </v-container>
</template>

<script>
export default {
  name: "IfElse",
  data() {
    return {
      error: false,
    };
  },
  computed: {
    questions() {
      return this.$store.getters["liDehnProject/getQuestions"];
    },
    questiontest() {
      if (this.questions[this.project.type] != null) {
        let question = this.questions[this.project.type].find(
          (question) => question.identifier === this.$route.params.identifier
        );
        this.setUp(question);
        return question;
      } else {
        return null;
      }
    },
  },
  mounted() {
    if (this.question === undefined) {
      this.error = true;
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.fromRoute = from;
    });
  },
  methods: {
    setUp(question) {
      if (question.type === '3' && question.buttons) {

      question.buttons.forEach((statement) => {
          if(statement.if === 'through'){
            this.selectQuestion(statement);
          } else {
            const ifArr = statement.if.split(".");
            
            if(ifArr.length > 1){
              let test =  this.pathIndex(this.$store.state.liDehnProject,statement.if);
                console.log('test',test);

              if (test === statement.is){
                this.selectQuestion(statement);
              }
            } else {
              let test = this.$store.state.liDehnProject.custom[statement.if];
              console.log('test',test);
              console.log(statement.is);
              if (test === statement.is){
                console.log('yes');
                this.selectQuestion(statement);
              }
            }
            
          }
        
        });
      }
    },
    multiIndex(obj,is) {  // obj,['1','2','3'] -> ((obj['1'])['2'])['3']
      return is.length ? this.multiIndex(obj[is[0]],is.slice(1)) : obj
    },
    pathIndex(obj,is) {   // obj,'1.2.3' -> multiIndex(obj,['1','2','3'])
      return this.multiIndex(obj,is.split('.'))
    },
    selectQuestion(btn) {
      if (btn.toValue != null && btn.toValue != "") {
        this.addCustomToStore(btn.toValue);
      }
      if (btn.toPrefix === "result") {
        this.$router.push("/result");
      } else {
        this.$router.push(`/${btn.toPrefix}${btn.to}`);
      }
    },
    goBack() {
      if (!this.fromRoute.name) {
        this.$router.push("/");
      } else {
        this.$router.go(-1);
      }
    },
  },
};
</script>
