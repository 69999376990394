<template>
  <v-container
    fluid
    class="fill-height primary"
    style="min-height: 100vh; padding-top: 155px; padding-bottom: 105px"
  >
    <v-container>
      <v-row align="center">
        <v-col cols="12">
          <!--  NO PROJECT -->
          <template v-if="error != ''">
            <v-card class="mx-auto text-left">
              <v-card-title class="text--secondary text-center">
                {{ appConfig.texts.result.noProject.title }}
              </v-card-title>
            </v-card>
          </template>
          <!-- END /  NO PROJECT -->
          <template v-else>
            <div
              class="white--text mb-5 ml-3"
              :class="[isMobile ? 'd-none' : 'd-inline-block text-h5']"
              v-html="appConfig.texts.result.title"
            />
            <div
              :class="[isMobile ? 'd-inline-block mb-5' : 'd-none']"
              v-html="'&nbsp;'"
            />
            <v-text-field
              v-model="project.name"
              hide-details
              class="white d-inline-block ml-sm-3 pl-3"
              :placeholder="appConfig.texts.result.projectName"
              dense
              @blur.prevent="updateLocalStorage"
            ></v-text-field>
            <!-- SAVE PDF -->
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  fab
                  small
                  dark
                  color="white"
                  :loading="loading"
                  :disabled="loading"
                  v-bind="attrs"
                  v-on="on"
                  class="ml-3 mb-3 float-end"
                  @click.prevent="generateReport"
                >
                  <v-icon dark class="primary--text">
                    {{ appConfig.texts.result.pdf.icon }}
                  </v-icon>
                </v-btn>
              </template>
              <span>{{ appConfig.texts.result.pdf.tooltip }}</span>
            </v-tooltip>
            <!-- SAVE PDF -->
            <!-- REFRESH -->
            <v-menu :close-on-content-click="true">
              <template v-slot:activator="{ on: menu, attrs }">
                <v-tooltip top>
                  <template v-slot:activator="{ on: tooltip }">
                    <v-btn
                      fab
                      small
                      dark
                      color="white"
                      v-bind="attrs"
                      v-on="{ ...tooltip, ...menu }"
                      class="ml-3 mb-3 float-end"
                    >
                      <v-icon dark class="primary--text">
                        {{ appConfig.texts.result.reset.icon }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>{{ appConfig.texts.result.reset.tooltip }}</span>
                </v-tooltip>
              </template>
              <v-card style="width: 280px; max-width: 280px">
                <v-list>
                  <v-list-item>
                    <v-list-item-content
                      v-html="appConfig.texts.result.reset.confirmText"
                    />
                  </v-list-item>
                </v-list>

                <v-card-actions>
                  <v-spacer></v-spacer>

                  <v-btn text @click.prevent="menu = false">
                    {{ appConfig.texts.result.reset.cancel }}
                  </v-btn>
                  <v-btn
                    color="primary"
                    text
                    @click.prevent="
                      resetProject(appConfig.texts.result.reset.to)
                    "
                  >
                    {{ appConfig.texts.result.reset.approve }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-menu>
            <!-- END REFRESH -->

            <div
              class="mb-5"
              v-if="appConfig.texts.result.subtitle != ''"
              v-html="appConfig.texts.result.subtitle"
            />
            <v-card class="mx-auto text-left">
              <!--  MAIN DISTRIBUTOR -->
              <v-card-title class="text--secondary">
                {{ appConfig.texts.result.mainDistributor.title }}
                <v-tooltip right v-if="mainDistributor === undefined">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      small
                      v-bind="attrs"
                      v-on="on"
                      class="mx-2"
                      @click.prevent="findMainDistributorLink"
                    >
                      <v-icon small>mdi-plus</v-icon>
                    </v-btn>
                  </template>
                  <span>{{
                    appConfig.texts.result.mainDistributor.tableAdd
                  }}</span>
                </v-tooltip>
              </v-card-title>
              <v-simple-table
                class="pb-10"
                :class="[isMobile ? 'mx-0' : 'mx-10']"
                v-if="mainDistributor != undefined"
              >
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left col-8" colspan="2">
                        {{
                          appConfig.texts.result.mainDistributor.tableProduct
                        }}
                      </th>
                      <th class="text-left">
                        {{ appConfig.texts.app.lpsClassOverview }}
                      </th>
                      <th class="text-left">
                        {{
                          appConfig.texts.result.mainDistributor.tableGridSystem
                        }}
                      </th>
                      <th>
                        {{ appConfig.texts.result.mainDistributor.tableName }}
                      </th>
                      <th class="text-right col-1">
                        {{
                          appConfig.texts.result.mainDistributor.tableActions
                        }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td
                        style="
                          text-align: center;
                          width: 50px;
                          padding: 10px 0px 10px 10px !important;
                        "
                      >
                        <v-img
                          :src="'/assets/images/' + mainDistributor.image"
                          max-width="50px"
                        />
                      </td>
                      <td class="col-7" style="min-width: 310px">
                        <div class="float-left">
                          <span v-html="mainDistributor.name"></span><br />
                          <template
                            v-if="
                              findProduct(mainDistributor.articleNo)
                                .combinedProduct
                            "
                          >
                            <span
                              class="grey--text"
                              v-html="
                                findProduct(mainDistributor.articleNo)
                                  .combinedProduct
                              "
                            />
                          </template>
                          <template v-else>
                            <span class="grey--text">{{
                              findProduct(mainDistributor.articleNo).articleNo
                            }}</span>
                            -
                            <span
                              class="grey--text"
                              v-html="
                                findProduct(mainDistributor.articleNo)
                                  .description
                              "
                            ></span>
                          </template>
                        </div>
                        <div
                          class="alertText float-left small"
                          v-html="project.main.alertText"
                        ></div>
                      </td>
                      <td
                        v-html="findProduct(mainDistributor.articleNo).lpsClass"
                      />
                      <td v-html="mainDistributor.grid" />
                      <td>
                        <v-text-field
                          v-model="project.main.name"
                          hide-details
                          dense
                          @blur.prevent="updateLocalStorage"
                        ></v-text-field>
                      </td>
                      <td class="text-right">
                        <!-- START  -- / REMOVE MAIN DISTRIBUTOR -->
                        <v-menu :close-on-content-click="true">
                          <template v-slot:activator="{ on: menu, attrs }">
                            <v-tooltip left>
                              <template v-slot:activator="{ on: tooltip }">
                                <v-btn
                                  icon
                                  small
                                  v-bind="attrs"
                                  v-on="{ ...tooltip, ...menu }"
                                >
                                  <v-icon small> mdi-delete </v-icon>
                                </v-btn>
                              </template>
                              <span>{{
                                appConfig.texts.result.mainDistributor
                                  .tableRemove
                              }}</span>
                            </v-tooltip>
                          </template>
                          <v-card style="width: 280px; max-width: 280px">
                            <v-list>
                              <v-list-item>
                                <v-list-item-content
                                  v-html="
                                    appConfig.texts.result.mainDistributor
                                      .confirmText
                                  "
                                />
                              </v-list-item>
                            </v-list>

                            <v-card-actions>
                              <v-spacer></v-spacer>

                              <v-btn text @click.prevent="menu = false">
                                {{
                                  appConfig.texts.result.mainDistributor.cancel
                                }}
                              </v-btn>
                              <v-btn
                                color="primary"
                                text
                                @click.prevent="removeMainDistributor"
                              >
                                {{
                                  appConfig.texts.result.mainDistributor.approve
                                }}
                              </v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-menu>
                        <!-- END  -- / REMOVE MAIN DISTRIBUTOR -->
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <!-- END / -  MAIN DISTRIBUTOR -->
              <!--  SUB DIVIDERS -->
              <v-card-title class="text--secondary">
                {{ appConfig.texts.result.dividers[project.type].title }}
                <v-tooltip right>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      small
                      v-bind="attrs"
                      v-on="on"
                      class="mx-2"
                      @click.prevent="findSubDividerLink"
                    >
                      <!-- :to="findSubDividerLink().to" -->
                      <v-icon small>mdi-plus</v-icon>
                    </v-btn>
                  </template>
                  <span>{{
                    appConfig.texts.result.dividers[project.type].tableAdd
                  }}</span>
                </v-tooltip>
              </v-card-title>
              <v-simple-table
                class="pb-10"
                :class="[isMobile ? 'mx-0' : 'mx-10']"
                v-if="project.dividers.length"
              >
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left col-8" colspan="2">
                        {{
                          appConfig.texts.result.dividers[project.type]
                            .tableProduct
                        }}
                      </th>
                      <th class="text-left">
                        {{ appConfig.texts.app.lpsClassOverview }}
                      </th>
                      <th class="text-left">
                        {{
                          appConfig.texts.result.dividers[project.type]
                            .tableGridSystem
                        }}
                      </th>
                      <th>
                        {{
                          appConfig.texts.result.dividers[project.type]
                            .tableName
                        }}
                      </th>
                      <th class="text-right col-1">
                        {{
                          appConfig.texts.result.dividers[project.type]
                            .tableActions
                        }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(product, n) in project.dividers" :key="n">
                      <td
                        style="
                          text-align: center;
                          width: 50px;
                          padding: 10px 0px 10px 10px !important;
                        "
                      >
                        <v-img
                          center
                          :src="
                            '/assets/images/' +
                            findProduct(product.articleNo).image
                          "
                          max-width="50px"
                        />
                      </td>
                      <td class="col-7" style="min-width: 310px">
                        <div class="float-left">
                          <span
                            v-html="findProduct(product.articleNo).name"
                          ></span
                          ><br />
                          <template
                            v-if="
                              findProduct(product.articleNo).combinedProduct
                            "
                          >
                            <span
                              class="grey--text"
                              v-html="
                                findProduct(product.articleNo).combinedProduct
                              "
                            />
                          </template>
                          <template v-else>
                            <span class="grey--text">{{
                              findProduct(product.articleNo).articleNo
                            }}</span>
                            -
                            <span
                              class="grey--text"
                              v-html="
                                findProduct(product.articleNo).description
                              "
                            ></span>
                          </template>
                        </div>
                        <div
                          class="alertText float-left"
                          v-html="product.alertText"
                        ></div>
                      </td>
                      <td v-html="findProduct(product.articleNo).lpsClass" />
                      <td v-html="findProduct(product.articleNo).grid" />
                      <td>
                        <v-text-field
                          v-model="product.name"
                          hide-details
                          dense
                          @blur.prevent="updateLocalStorage"
                        ></v-text-field>
                      </td>
                      <td class="text-right">
                        <!-- START  -- / REMOVE SUB DIVIDER -->
                        <v-menu :close-on-content-click="true">
                          <template v-slot:activator="{ on: menu, attrs }">
                            <v-tooltip left>
                              <template v-slot:activator="{ on: tooltip }">
                                <v-btn
                                  icon
                                  small
                                  v-bind="attrs"
                                  v-on="{ ...tooltip, ...menu }"
                                >
                                  <v-icon small> mdi-delete </v-icon>
                                </v-btn>
                              </template>
                              <span>{{
                                appConfig.texts.result.dividers[project.type]
                                  .tableRemove
                              }}</span>
                            </v-tooltip>
                          </template>
                          <v-card style="width: 280px; max-width: 280px">
                            <v-list>
                              <v-list-item>
                                <v-list-item-content
                                  v-html="
                                    appConfig.texts.result.dividers[
                                      project.type
                                    ].confirmText
                                  "
                                />
                              </v-list-item>
                            </v-list>

                            <v-card-actions>
                              <v-spacer></v-spacer>

                              <v-btn text @click.prevent="menu = false">
                                {{
                                  appConfig.texts.result.dividers[project.type]
                                    .cancel
                                }}
                              </v-btn>
                              <v-btn
                                color="primary"
                                text
                                @click.prevent="removeSubDivider(n)"
                              >
                                {{
                                  appConfig.texts.result.dividers[project.type]
                                    .approve
                                }}
                              </v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-menu>
                        <!-- END  -- / REMOVE SUB DIVIDER -->
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <!-- END / - SUB DIVIDERS -->
              <!--  CRIT END GROUPS -->
              <template v-if="project.type != '00r'">
                <v-card-title class="text--secondary">
                  {{ appConfig.texts.result.critEndGroups.title }}
                  <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        small
                        v-bind="attrs"
                        v-on="on"
                        class="mx-2"
                        @click.prevent="findCritEndGroup"
                      >
                        <!-- :to="findCritEndGroup().to" -->
                        <v-icon small>mdi-plus</v-icon>
                      </v-btn>
                    </template>
                    <span>{{
                      appConfig.texts.result.critEndGroups.tableAdd
                    }}</span>
                  </v-tooltip>
                </v-card-title>

                <v-simple-table
                  class="pb-10"
                  :class="[isMobile ? 'mx-0' : 'mx-10']"
                  v-if="project.critEndGroups.length"
                >
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left col-8" colspan="2">
                          {{
                            appConfig.texts.result.critEndGroups.tableProduct
                          }}
                        </th>
                        <th class="text-left">
                          {{ appConfig.texts.app.lpsClassOverview }}
                        </th>
                        <th class="text-left">
                          {{ appConfig.texts.result.critEndGroups.tableAmpere }}
                        </th>
                        <th>
                          {{ appConfig.texts.result.critEndGroups.tableName }}
                        </th>
                        <th class="text-right col-1">
                          {{
                            appConfig.texts.result.critEndGroups.tableActions
                          }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(product, n) in project.critEndGroups"
                        :key="n"
                      >
                        <td
                          style="
                            text-align: center;
                            width: 50px;
                            padding: 10px 0px 10px 10px !important;
                          "
                        >
                          <v-img
                            center
                            :src="
                              '/assets/images/' +
                              findProduct(product.articleNo).image
                            "
                            max-width="50px"
                          />
                        </td>
                        <td class="col-7" style="min-width: 310px">
                          <div class="float-left">
                            <span
                              v-html="findProduct(product.articleNo).name"
                            ></span
                            ><br />
                            <span class="grey--text">{{
                              findProduct(product.articleNo).articleNo
                            }}</span>
                            -
                            <span
                              class="grey--text"
                              v-html="
                                findProduct(product.articleNo).description
                              "
                            ></span>
                          </div>
                          <div
                            class="alertText float-left"
                            v-html="product.alertText"
                          ></div>
                        </td>
                        <td v-html="findProduct(product.articleNo).lpsClass" />
                        <td v-html="findProduct(product.articleNo).ampere" />
                        <td>
                          <v-text-field
                            v-model="product.name"
                            hide-details
                            dense
                            @blur.prevent="updateLocalStorage"
                          ></v-text-field>
                        </td>
                        <td class="text-right">
                          <!-- START  -- / REMOVE SUB DIVIDER -->
                          <v-menu :close-on-content-click="true">
                            <template v-slot:activator="{ on: menu, attrs }">
                              <v-tooltip left>
                                <template v-slot:activator="{ on: tooltip }">
                                  <v-btn
                                    icon
                                    small
                                    v-bind="attrs"
                                    v-on="{ ...tooltip, ...menu }"
                                  >
                                    <v-icon small> mdi-delete </v-icon>
                                  </v-btn>
                                </template>
                                <span>{{
                                  appConfig.texts.result.critEndGroups
                                    .tableRemove
                                }}</span>
                              </v-tooltip>
                            </template>
                            <v-card style="width: 280px; max-width: 280px">
                              <v-list>
                                <v-list-item>
                                  <v-list-item-content
                                    v-html="
                                      appConfig.texts.result.critEndGroups
                                        .confirmText
                                    "
                                  />
                                </v-list-item>
                              </v-list>

                              <v-card-actions>
                                <v-spacer></v-spacer>

                                <v-btn text @click.prevent="menu = false">
                                  {{
                                    appConfig.texts.result.critEndGroups.cancel
                                  }}
                                </v-btn>
                                <v-btn
                                  color="primary"
                                  text
                                  @click.prevent="removeCritEndGroup(n)"
                                >
                                  {{
                                    appConfig.texts.result.critEndGroups.approve
                                  }}
                                </v-btn>
                              </v-card-actions>
                            </v-card>
                          </v-menu>
                          <!-- END  -- / REMOVE SUB DIVIDER -->
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </template>
              <!-- END / -  CRIT END GROUPS -->
            </v-card>
          </template>
        </v-col>
      </v-row>
      <v-row
        align="center"
        justify="center"
        :class="{ 'px-5': isMobile }"
        class="mb-15"
      >
        <template v-if="error != ''">
          <!-- NO PROJECT TYPE -->
          <v-btn
            class="mx-5"
            depressed
            color="white"
            :block="isMobile"
            :class="['primary--text', isMobile ? 'my-1' : '']"
            @click="$router.push('/type')"
          >
            {{ appConfig.texts.result.noProject.btn }}
          </v-btn>
        </template>
      </v-row>
    </v-container>
    <v-container v-if="error === '' && calculatedItems != null">
      <vue-html2pdf
        :show-layout="false"
        :float-layout="true"
        :enable-download="true"
        :preview-modal="false"
        :paginate-elements-by-height="900"
        :filename="
          (appConfig.texts.result.pdf.fileName +
            project.name +
            '-' +
            currentDate)
            | slugify
        "
        :pdf-quality="2"
        :manual-pagination="true"
        pdf-format="a4"
        pdf-orientation="portrait"
        pdf-content-width="100%"
        @progress="onProgress($event)"
        @hasStartedGeneration="hasStartedGeneration()"
        @hasGenerated="hasGenerated($event)"
        ref="html2Pdf"
      >
        <section class="arial pdf-file" slot="pdf-content">
          <section class="pdf-item header">
            <div class="top">
              <svg
                version="1.1"
                id="logo"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                viewBox="0 0 150 50"
                class="dehnLogoPdf"
                width="200"
              >
                <g>
                  <polygon
                    fill="#e3000b"
                    points="84.8,35.5 86.5,26.7 112.5,20.8 	"
                  />
                  <polygon
                    fill="#e3000b"
                    points="40.7,14.5 39,23.3 12.5,29.4 	"
                  />
                  <polygon
                    fill="#e3000b"
                    points="51.2,34.6 58.8,32.8 59.4,29.9 55.6,30.8 56.2,27.9 59.9,27 60.5,24.1 56.7,25 57.3,22.1 61.1,21.2 61.6,18.3 54.1,20.1"
                  />
                  <polygon
                    fill="#e3000b"
                    points="61.3,32.3 65.1,31.4 66.3,25.6 68.8,25 67.7,30.8 71.4,29.9 74.3,15.4 70.5,16.3 69.4,22.1 66.8,22.7 68,16.9 64.2,17.7"
                  />
                  <polygon
                    fill="#e3000b"
                    points="74,29.3 77.8,28.5 79.1,21.3 80.3,27.9 84.1,27 86.9,12.5 83.1,13.4 81.7,20.4 80.6,14 76.8,14.8"
                  />
                  <g>
                    <path
                      fill="#e3000b"
                      d="M46.9,25.7c0.2-1-0.2-1.2-1-1L44.7,25L43,33.7l1.3-0.3c0.8-0.2,1.3-0.3,1.5-1.3L46.9,25.7z M47.3,21.6 c2.5-0.6,3.8,1,3.5,2.9L49.1,33c-0.4,2.2-1.7,2.5-5.1,3.3l-5.5,1.3L41.4,23L47.3,21.6z"
                    />
                  </g>
                </g>
              </svg>
              <div class="generated-title">
                <h3
                  class="file-title"
                  v-html="appConfig.texts.result.pdf.fileTitle"
                ></h3>
                <p
                  class="file-subtitle"
                  v-html="appConfig.texts.result.pdf.fileSubTitle"
                ></p>
                <h3 class="project-title">
                  {{ appConfig.texts.result.title }}: {{ project.name }}
                  <br />
                </h3>
              </div>
              <div class="date">
                {{ currentDate }}
              </div>
            </div>
          </section>
          <!-- MAIN DISTRIBUTOR -->
          <section class="pdf-item">
            <h1>{{ appConfig.texts.result.mainDistributor.title }}</h1>
            <table class="arial pdf-table" v-if="mainDistributor != undefined">
              <thead>
                <tr>
                  <th class="text-left" colspan="2">
                    {{ appConfig.texts.result.mainDistributor.tableProduct }}
                  </th>
                  <th class="text-left">
                    {{ appConfig.texts.app.lpsClassOverview }}
                  </th>
                  <th class="text-left">
                    {{ appConfig.texts.result.mainDistributor.tableGridSystem }}
                  </th>
                  <th class="text-left">
                    {{ appConfig.texts.result.mainDistributor.tableName }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr style="height: 80px">
                  <td width="80px">
                    <img
                      :src="'/assets/images/' + mainDistributor.image"
                      width="50px"
                    />
                  </td>
                  <td width="70%">
                    <div class="float-left">
                      <span v-html="mainDistributor.name"></span><br />
                      <template
                        v-if="
                          findProduct(mainDistributor.articleNo).combinedProduct
                        "
                      >
                        <span
                          class="grey--text"
                          v-html="
                            findProduct(mainDistributor.articleNo)
                              .combinedProduct
                          "
                        />
                      </template>
                      <template v-else>
                        <span class="grey--text">{{
                          findProduct(mainDistributor.articleNo).articleNo
                        }}</span>
                        -
                        <span
                          class="grey--text"
                          v-html="
                            findProduct(mainDistributor.articleNo).description
                          "
                        ></span>
                      </template>
                    </div>
                    <div
                      class="float-left alertText"
                      v-html="stripTags(this.project.main.alertText)"
                    ></div>
                  </td>
                  <td
                    v-html="findProduct(mainDistributor.articleNo).lpsClass"
                  />
                  <td v-html="mainDistributor.grid" />
                  <td>
                    {{ project.main.name }}
                  </td>
                </tr>
              </tbody>
            </table>
          </section>
          <!-- END / - MAIN DISTRIBUTOR -->
          <!--  SUB DIVIDER(S) -->
          <section class="pdf-item" v-if="calculatedItems[0].dividers.length">
            <h1>{{ appConfig.texts.result.dividers[project.type].title }}</h1>
            <table class="arial pdf-table">
              <thead>
                <tr>
                  <th class="text-left" colspan="2">
                    {{
                      appConfig.texts.result.dividers[project.type].tableProduct
                    }}
                  </th>
                  <th class="text-left">
                    {{ appConfig.texts.app.lpsClassOverview }}
                  </th>
                  <th class="text-left">
                    {{
                      appConfig.texts.result.dividers[project.type]
                        .tableGridSystem
                    }}
                  </th>
                  <th class="text-left">
                    {{
                      appConfig.texts.result.dividers[project.type].tableName
                    }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <template v-for="(product, n) in calculatedItems[0].dividers">
                  <tr :key="n" style="height: 80px">
                    <td width="80px">
                      <img
                        :src="
                          '/assets/images/' +
                          findProduct(product.articleNo).image
                        "
                        width="50px"
                      />
                    </td>
                    <td width="70%">
                      <div class="float-left">
                        <span
                          v-html="findProduct(product.articleNo).name"
                        ></span
                        ><br />
                        <template
                          v-if="findProduct(product.articleNo).combinedProduct"
                        >
                          <span
                            class="grey--text"
                            v-html="
                              findProduct(product.articleNo).combinedProduct
                            "
                          />
                        </template>
                        <template v-else>
                          <span class="grey--text">{{
                            findProduct(product.articleNo).articleNo
                          }}</span>
                          -
                          <span
                            class="grey--text"
                            v-html="findProduct(product.articleNo).description"
                          ></span>
                        </template>
                      </div>
                      <div
                        class="float-left alertText"
                        v-html="stripTags(product.alertText)"
                      ></div>
                    </td>
                    <td v-html="findProduct(product.articleNo).lpsClass" />
                    <td v-html="findProduct(product.articleNo).grid" />
                    <td>
                      {{ product.name }}
                    </td>
                  </tr>
                  <div
                    :key="`break${n}`"
                    v-if="product.number == 1"
                    class="html2pdf__page-break"
                  />
                </template>
              </tbody>
            </table>
          </section>
          <!-- END / - SUB DIVIDER(S) -->
          <!--  CRIT END GROUPS -->
          <section
            class="pdf-item"
            v-if="
              project.type != '00r' &&
              calculatedItems[0].criticalEndGroups.length
            "
          >
            <h1>{{ appConfig.texts.result.critEndGroups.title }}</h1>
            <table class="arial pdf-table">
              <thead>
                <tr>
                  <th class="text-left" colspan="2">
                    {{ appConfig.texts.result.critEndGroups.tableProduct }}
                  </th>
                  <th class="text-left">
                    {{ appConfig.texts.app.lpsClassOverview }}
                  </th>
                  <th class="text-left">
                    {{ appConfig.texts.result.critEndGroups.tableAmpere }}
                  </th>
                  <th class="text-left">
                    {{ appConfig.texts.result.critEndGroups.tableName }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <template
                  v-for="(product, n) in calculatedItems[0].criticalEndGroups"
                >
                  <tr :key="n" style="height: 80px">
                    <td width="80px">
                      <img
                        :src="
                          '/assets/images/' +
                          findProduct(product.articleNo).image
                        "
                        width="50px"
                      />
                    </td>
                    <td width="70%">
                      <div class="float-left">
                        <span
                          v-html="findProduct(product.articleNo).name"
                        ></span
                        ><br />
                        <span class="grey--text">{{
                          findProduct(product.articleNo).articleNo
                        }}</span>
                        -
                        <span
                          class="grey--text"
                          v-html="findProduct(product.articleNo).description"
                        ></span>
                      </div>
                      <div
                        class="float-left alertText"
                        v-html="stripTags(product.alertText)"
                      ></div>
                    </td>
                    <td v-html="findProduct(product.articleNo).lpsClass" />
                    <td v-html="findProduct(product.articleNo).ampere" />
                    <td>
                      {{ product.name }}
                    </td>
                  </tr>
                  <!-- <div :key="`break${n}`" v-if="product.number == 1" class="html2pdf__page-break" /> -->
                </template>
              </tbody>
            </table>
            <!-- END / -  CRIT END GROUPS -->
          </section>
        </section>
      </vue-html2pdf>
    </v-container>
  </v-container>
</template>

<script>
import VueHtml2pdf from "vue-html2pdf";
export default {
  title: "",
  name: "Result",
  components: {
    VueHtml2pdf,
  },
  data() {
    return {
      loading: false,
      calculatedItems: null,
      currentNumber: 0,
      currentPage: 0,
      fromRoute: null,
      error: "",
      reset: false,
      currentDate: null,
    };
  },
  mounted() {
    if (this.project.type == "") {
      this.error = "no project";
    }
    if (this.project.grid == "") {
      this.error = "no project";
    }
    if (this.project.grid == "") {
      this.error = "no project";
    }
    this.currentDate = this.getCurrentDate();
    this.setupTitle();
  },
  computed: {
    questions() {
      return this.$store.getters["liDehnProject/getQuestions"];
    },
    mainDistributor() {
      return this.appConfig.products.find(
        (product) => product.articleNo === this.project.main.articleNo
      );
    },
  },
  watch: {},
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.fromRoute = from;
    });
  },
  methods: {
    setupTitle() {
      this.$title = `Result: ${this.getCurrentDate()}`;
    },
    getCurrentDate() {
      const dateObj = new Date();
      const month = this.appConfig.texts.monthNames[dateObj.getMonth()];
      const day = String(dateObj.getDate()).padStart(2, "0");
      const year = dateObj.getFullYear();

      return day + "\n" + month + "\n" + year;
    },
    /*
     Generate Report using refs and calling the
     refs function generatePdf()
     */
    generateReport() {
      this.loading = true;
      setTimeout(() => {
        this.currentNumber = 7;
        this.currentPage = 1;
        let items = [
          {
            dividers: this.project.dividers,
            criticalEndGroups: this.project.critEndGroups,
          },
        ];
        items.forEach((item) => {
          Object.values(item).forEach((val) => {
            val.forEach((element) => {
              element.number = this.currentNumber;
              if (this.currentNumber > 11) {
                this.currentNumber = 1;
                this.currentPage++;
              } else if (this.currentNumber <= 11) {
                this.currentNumber++;
              }
            });
          });
        });
        this.calculatedItems = items;
        this.$nextTick(() => {
          this.$refs.html2Pdf.generatePdf();
        });
      }, 500);
    },
    onProgress(event) {
      if (event == 100) {
        setTimeout(() => {
          this.loading = false;
        }, 1000);
      }
    },
    hasStartedGeneration() {
      console.debug("hasStartedGeneration");
    },
    hasGenerated(event) {
      console.log("hasGenerated", event);
    },
    findMainDistributorLink() {
      let to = this.questions[this.project.type].find(
        (question) => question.name === "addMainDistributor"
      );
      this.setProjectDialog(false);
      this.$router.push(`/ie/${to.identifier}`);
    },
    findCritEndGroup() {
      let to = this.questions[this.project.type].find(
        (question) => question.name === "addCriticalEndGroup"
      );
      this.setProjectDialog(false);
      this.$router.push(`/ie/${to.identifier}`);
    },
    findSubDividerLink() {
      let to = this.questions[this.project.type].find(
        (question) => question.name === "addSubDivider"
      );
      this.setProjectDialog(false);
      this.$router.push(`/ie/${to.identifier}`);
    },
    removeSubDivider(n) {
      this.setProjectDialog(false);
      this.$store.dispatch("liDehnProject/REMOVE_DIVIDER", n);
    },
    removeCritEndGroup(n) {
      this.setProjectDialog(false);
      this.$store.dispatch("liDehnProject/REMOVE_CRIT_END_GROUP", n);
    },
    updateLocalStorage() {
      this.$store.dispatch("liDehnProject/UPDATE_LOCALSTORAGE");
    },
    findProduct(articleNo) {
      return this.appConfig.products.find(
        (product) => product.articleNo === articleNo
      );
    },
    removeMainDistributor() {
      this.setProjectDialog(false);
      this.menu = false;
      this.$store.dispatch("liDehnProject/REMOVE_MAIN_DISTRIBUTOR");
      this.findMainDistributorLink();
      // this.$router.push(to);
    },

    resetProject(to) {
      this.setProjectDialog(false);
      this.menu = false;
      this.$store.dispatch("liDehnProject/SET_PROJECT");
      this.$router.push(to);
    },
    goBack() {
      if (!this.fromRoute.name) {
        this.$router.push("/");
      } else {
        this.$router.go(-1);
      }
    },
  },
};
</script>

<style lang="scss">
.alertText {
  p {
    font-size: 10px !important;
    margin-bottom: 0px;
    color: #e3000b;
    padding-left: 25px;
    text-align: left !important;
  }
}
.pdf-file {
  background-color: #ffffff;
  padding: 25px;
  float: left;
  width: 100%;
  font-size: 10px;

  .pdf-item {
    float: left;
    width: 100%;
    &:not(.header) {
      margin-top: 25px;
    }
    .alertText {
      font-size: 7px;
    }
  }
  .header {
    .top {
      display: block;
      width: 100%;
      float: left;
    }
    .dehnLogoPdf {
      float: left;
    }
    .generated-title {
      margin: 0px -10px 15px 25px;
      float: left;
    }
    .date {
      margin: 0px 15px 15px 0px;
      float: right;
    }
  }
  p {
    margin: 0 0 15px;
  }
  h1 {
    font-size: 20px;
    margin-bottom: 10px;
    &.project-title {
      border-bottom: 0px;
      display: block;
      float: left;
    }
  }
  .text-left {
    text-align: left;
  }
  .grey--text {
    color: #9e9e9e !important;
  }
  table {
    border-collapse: collapse;
  }
  .pdf-table {
    width: 100%;
    thead {
      tr {
        border-bottom: 1px solid #9e9e9e;
        border-top: 1px solid #9e9e9e;
      }
    }
    th {
      padding: 10px 5px;
    }
    tbody {
      tr {
        td {
          padding: 10px 5px;
        }
      }
    }
  }
}

.arial {
  font-family: Arial;
}
</style>
