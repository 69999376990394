<template>
  <v-container fluid class="fill-height grey" style="min-height: 100vh">
    <v-container>
      <template v-if="question != null">
        <v-row
          align="center"
          justify="start"
          justify-md="space-around"
          class="px-5"
        >
          <v-col cols="12" lg="8" class="text-center">
            <v-sheet elevation="5" class="py-15 px-5">
              <template v-if="debug">
                <p v-text="question.identifier" />
                <p v-text="question.text" />
              </template>
              <p
                class="mb-5"
                :class="[isMobile ? 'text-h5' : 'text-h4']"
                v-html="question.question"
              />
              <div class="mt-15">
                <template v-for="(btn, n) in question.buttons">
                  <v-btn
                    class="mx-sm-5"
                    depressed
                    color="primary"
                    :block="isMobile"
                    :key="n"
                    :class="[
                      project.grid === btn.grid ? 'darken-3' : '',
                      isMobile ? 'my-1' : '',
                    ]"
                    @click.prevent="selectGrid(btn)"
                  >
                    <template v-if="project.grid === btn.grid">
                      <v-icon class="mr-2">mdi-check</v-icon>
                      {{ btn.name }}
                    </template>
                    <template v-else>
                       <v-icon v-if="btn.icon && btn.icon != ''" class="mr-2">
                        {{ btn.icon }}
                      </v-icon>
                      {{ btn.name }}
                    </template>
                  </v-btn>
                </template>
              </div>
            </v-sheet>
          </v-col>
        </v-row>
      </template>
      <template v-else>
        <v-dialog v-model="error" persistent max-width="290">
          <v-card>
            <v-card-title class="text-h5">
              {{ appConfig.texts.errors.qNotFoundTitle }}
            </v-card-title>
            <v-card-text>
              {{ appConfig.texts.errors.qNotFoundText }}
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click.prevent="goBack">
                {{ appConfig.texts.errors.qNotFoundButton }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
    </v-container>
  </v-container>
</template>

<script>
export default {
  title: "",
  name: "Question",
  data() {
    return {
      fromRoute: null,
      error: false,
    };
  },
  computed: {
   questions() {
      return this.$store.getters["liDehnProject/getQuestions"];
    },
    question() {
      if (this.questions[this.project.type] != null) {
        let currentQuestion = this.questions[this.project.type].find(
          (question) => question.identifier === this.$route.params.identifier
        );
        return currentQuestion;
      } else {
        return null;
      }
    },
  },
  mounted() {
    if (this.question === undefined) {
      this.error = true;
    } else {
      this.setupTitle();
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.fromRoute = from;
    });
  },
  methods: {
    setupTitle(){
      
      if (this.question == null){
        setTimeout(() => {
          this.setupTitle();
        }, 500);
      } else {
          this.$title = `${this.$options.filters.trimLength(this.stripTags(this.question.question),15)}`;
      }
      
      // console.log(this.question.question);
    },
    selectGrid(btn) {
      this.setProjectDialog(false);
      this.$store.dispatch("liDehnProject/SET_GRID", btn.toValue);
      if(btn.toPrefix === "result"){
        this.$router.push('/result');
      } else {
        this.$router.push(`/${btn.toPrefix}${btn.to}`);
      }
    },
    goBack() {
      if (!this.fromRoute.name) {
        this.$router.push("/");
      } else {
        this.$router.go(-1);
      }
    },
  },
};
</script>
