<template>
  <v-container fluid class="fill-height primary" style="min-height: 100vh">
    <template v-if="question != null">
      <v-row align="center" style="margin-top: 85px">
        <v-col align="center" cols="12">
          <template v-if="debug">
            <p v-text="question.identifier" />
            <p v-text="question.text" />
          </template>
          <div
            class="white--text mb-5"
            :class="[isMobile ? 'text-h5' : 'text-h4']"
            v-html="question.question"
          />
          <v-alert
            max-width="344"
            v-if="question.alertText != ''"
            border="bottom"
            :class="[question.alertClass]"
            dark
            v-html="question.alertText"
          />
          <!-- SINLGE PRODUCT -->
          <v-card
            class="mx-auto text-left"
            max-width="344"
            v-if="
              product != null &&
              (productsArray === null || productsArray === false)
            "
          >
            <v-img
              contain
              :src="'/assets/images/' + product.image"
              height="240px"
              class="grey lighten-4 align-end"
            >
              <!-- PRODUCT INFO -->
              <template v-if="typeof product.url == 'string'">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      fab
                      small
                      color="white"
                      v-bind="attrs"
                      v-on="on"
                      class="mx-3 my-3 float-end"
                      @click="openUrl(product.url)"
                    >
                      <v-icon>mdi-information-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ appConfig.texts.app.productInfo }}</span>
                </v-tooltip>
              </template>
              <template v-else>
                <v-tooltip top v-for="(url, index) in product.url" :key="index">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      fab
                      small
                      color="white"
                      v-bind="attrs"
                      v-on="on"
                      class="mx-3 my-3"
                      :class="url.class"
                      @click="openUrl(url.url)"
                    >
                      <v-icon>mdi-information-outline</v-icon>
                    </v-btn>
                  </template>
                  <span
                    >{{ appConfig.texts.app.productInfo }} {{ url.name }}</span
                  >
                </v-tooltip>
              </template>
              <!-- PRODUCT INFO -->
            </v-img>
            <v-card-title v-html="product.name" />
            <v-card-subtitle v-if="product.combinedProduct">
              <v-row justify="space-between" class="px-3 pb-4">
                <div v-html="product.combinedProduct"></div>
                <div>{{ product.description }}<br /></div>
              </v-row>
            </v-card-subtitle>
            <v-card-subtitle v-else>
              <v-row justify="space-between" class="px-3 pb-4 caption">
                <div>
                  {{ product.articleNoNew }} - {{ product.description }} <br />
                </div>
                <div>{{ product.ampere }}<br /></div>
                <div>
                  {{ appConfig.texts.app.type }}:
                  {{ product.type }}
                  <div v-if="product.lpsClass !=''">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on">
                          {{ appConfig.texts.app.lpsClass }}<sup>*</sup>
                        </span>
                      </template>
                      <span
                        >* {{ appConfig.texts.app.lpsClassDescription }}
                      </span>
                    </v-tooltip>
                    :
                    {{ product.lpsClass }}
                  </div>
                </div>
              </v-row>
            </v-card-subtitle>
          </v-card>
          <template
            v-if="
              product != null &&
              (productsArray === null || productsArray === false)
            "
          >
            <template v-for="(btn, n) in question.buttons">
              <span
                :key="n"
                class="px-3 pb-4 white mx-sm-5 my-10"
                style="display: flex; width: 344px; max-width: 344px"
              >
                <v-text-field
                  v-model="name"
                  :placeholder="appConfig.texts.product.select.placeholder"
                  hide-details
                ></v-text-field>

                <v-btn
                  style="margin-top: 16px; height: 32px"
                  depressed
                  small
                  color="primary"
                  :block="isMobile"
                  :class="[btn.class, isMobile ? 'my-1' : '']"
                  @click.prevent="setProduct(btn)"
                >
                  <v-icon v-if="btn.icon && btn.icon != ''" class="mr-2">
                    {{ btn.icon }}
                  </v-icon>
                  {{ btn.name }}
                </v-btn>
              </span>
            </template>
          </template>
          <!-- MULTIPLE PRODUCT -->
          <v-row dense align="center" justify="center" v-else>
            <v-col
              cols="12"
              sm="4"
              md="4"
              lg="4"
              align="center"
              justify="center"
              v-for="(product, n) in productsArray"
              :key="n"
            >
              <div class="text-h4 white--text mb-5 mt-5" align-center>
                {{ product.title }}<br />
              </div>
              <v-card class="text-left mx-10" max-width="344">
                <v-img
                  contain
                  :src="'/assets/images/' + product.image"
                  height="240px"
                  class="grey lighten-4 align-end"
                >
                  <!-- PRODUCT INFO -->
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        fab
                        small
                        color="white"
                        v-bind="attrs"
                        v-on="on"
                        class="mx-3 my-3 float-end"
                        @click="openUrl(product.url)"
                      >
                        <v-icon>mdi-information-outline</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ appConfig.texts.app.productInfo }}</span>
                  </v-tooltip>
                  <!-- PRODUCT INFO -->
                </v-img>
                <v-card-title v-html="product.name" />
                <v-card-subtitle v-if="product.combinedProduct">
                  <v-row justify="space-between" class="px-3 pb-4">
                    <div v-html="product.combinedProduct"></div>
                    <div>{{ product.description }}<br /></div>
                  </v-row>
                </v-card-subtitle>
                <v-card-subtitle v-else>
                  <v-row justify="space-between" class="px-3 pb-4 caption">
                    <div>
                      {{ product.articleNo }} - {{ product.description }} <br />
                    </div>
                    <div>{{ product.ampere }}<br /></div>
                    <div>
                      {{ appConfig.texts.app.type }}:
                      {{ product.type }}
                       <div v-if="product.lpsClass !=''">
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <span v-bind="attrs" v-on="on">
                              {{ appConfig.texts.app.lpsClass }}<sup>*</sup>
                            </span>
                          </template>
                          <span
                            >* {{ appConfig.texts.app.lpsClassDescription }}
                          </span>
                        </v-tooltip>
                        :
                        {{ product.lpsClass }}
                      </div>
                    </div>
                  </v-row>
                </v-card-subtitle>
                <!-- {{product.type}} -->
              </v-card>
              <template v-for="(btn, n) in question.buttons">
                <span
                  :key="n"
                  class="px-3 pb-4 white mx-sm-5 my-10"
                  style="display: flex; width: 344px; max-width: 344px"
                >
                  <v-text-field
                    v-model="product.userDefinedName"
                    :placeholder="appConfig.texts.product.select.placeholder"
                    hide-details
                  ></v-text-field>
                  <v-btn
                    style="margin-top: 16px; height: 32px"
                    depressed
                    small
                    color="primary"
                    :block="isMobile"
                    :class="[btn.class, isMobile ? 'my-1' : '']"
                    @click.prevent="setProductArray(product, btn)"
                  >
                    <v-icon v-if="btn.icon && btn.icon != ''" class="mr-2">
                      {{ btn.icon }}
                    </v-icon>
                    {{ btn.name }}
                  </v-btn>
                </span>
              </template>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </template>
    <template v-else>
      <v-dialog v-model="error" persistent max-width="290">
        <v-card>
          <v-card-title class="text-h5">
            {{ appConfig.texts.errors.qNotFoundTitle }}
          </v-card-title>
          <v-card-text>
            {{ appConfig.texts.errors.qNotFoundText }}
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click.prevent="goBack">
              {{ appConfig.texts.errors.qNotFoundButton }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
  </v-container>
</template>

<script>
export default {
  title:"",
  name: "SubDividerResidential",
  data() {
    return {
      fromRoute: null,
      error: false,
      name: "",
    };
  },
  computed: {
    questions() {
      return this.$store.getters["liDehnProject/getQuestions"];
    },
    question() {
      if (this.questions[this.project.type] != null) {
        let currentQuestion = this.questions[this.project.type].find(
          (question) => question.identifier === this.$route.params.identifier
        );
        if (typeof currentQuestion.product != "object") {
          currentQuestion.product = JSON.parse(currentQuestion.product);
        }
        return currentQuestion;
      } else {
        return false;
      }
    },
    computedProjectGrid() {
      if (
        this.project.type != "00r" &&
        this.project != null &&
        this.project.grid != undefined &&
        this.project.grid != "" &&
        this.project.grid != "-"
      ) {
        // return false;
        return this.appConfig.grid.find(
          (grid) => grid.identifier === this.project.grid
        ).name;
      } else if (
        this.project.type == "00r" &&
        this.project != null &&
        this.project.grid == "-"
      ) {
        return this.appConfig.grid.find((grid) => grid.identifier === "TT")
          .name;
      } else {
        return false;
      }
    },
    product() {
      if (this.question && this.computedProjectGrid) {
        if (Array.isArray(this.question.product[this.computedProjectGrid])) {
          return null;
        } else {
          return this.appConfig.products.find(
            (product) =>
              product.articleNo ===
              this.question.product[this.computedProjectGrid]
          );
        }
      } else {
        return false;
      }
    },
    productsArray() {
      if (
        this.question &&
        this.computedProjectGrid &&
        Array.isArray(this.question.product[this.computedProjectGrid])
      ) {
        // MULTIPLE PRODUCTS
        let productsArray = [];
        this.question.product[this.computedProjectGrid].forEach((item) => {
          let product = this.appConfig.products.find(
            (product) => product.articleNo === item.articleNo
          );
          product.title = item.title;
          product.userDefinedName = "";
          productsArray.push(product);
        });
        return productsArray;
      } else {
        return false;
      }
    },
  },
mounted() {
    if (this.question === undefined) {
      this.error = true;
    } else {
      this.setupTitle();
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.fromRoute = from;
    });
  },
  methods: {
     setupTitle(){
      if (this.question == null){
        setTimeout(() => {
          this.setupTitle();
        }, 500);
      } else {
        this.$title = `SDR: ${this.$options.filters.trimLength(this.stripTags(this.product.name),15)} - ${this.product.articleNoNew}`;
      }
    },
    setProduct(btn) {
      this.setProjectDialog(false);
      let _divider = {
        articleNo: this.product.articleNo,
        name: this.name,
        alertText: this.question.alertText,
      };
      this.name = "";
      if(btn.toValue!= null && btn.toValue != ""){
       this.addCustomToStore(btn.toValue);
      }
      this.$store.dispatch("liDehnProject/SET_DIVIDER", _divider);
      if (btn.toPrefix === "result") {
        this.$router.push("/result");
      } else {
        this.$router.push(`/${btn.toPrefix}${btn.to}`);
      }
    },
    setProductArray(product, btn) {
      this.setProjectDialog(false);
      let _article = {
        articleNo: product.articleNo,
        name: product.userDefinedName,
        alertText: this.question.alertText,
      };
      this.$store.dispatch("liDehnProject/SET_DIVIDER", _article);
      product.userDefinedName = "";
      if(btn.toValue!= null && btn.toValue != ""){
       this.addCustomToStore(btn.toValue);
      }
      if (btn.toPrefix === "result") {
        this.$router.push("/result");
      } else {
        this.$router.push(`/${btn.toPrefix}${btn.to}`);
      }
    },
    goBack() {
      if (!this.fromRoute.name) {
        this.$router.push("/");
      } else {
        this.$router.go(-1);
      }
    },
  },
};
</script>
